<template>
    <div id="page-header">
        <NavContainer :scrolled="this.scrolled" :scrolledToProjects="this.scrolledToProjects"/>
        <div class="header-main">
            <LogoContainer class="header-logo"/>
            <div class="header-content">
                <h1 class="header-name">Hi, my name is Hannes</h1>
                <h2 class="header-description">An electrical engineer with a master’s degree in robotics 🤖</h2>
            </div>
        </div>
    </div>
</template>

<script>
import LogoContainer from './LogoContainer.vue'
import NavContainer from './NavContainer.vue'

export default {
    name: 'PageHeader',
    components: {
        LogoContainer,
        NavContainer
    },
    props: ['scrolledToProjects'],
    mounted() {
        this.headerObserver.observe(this.$el);
    },
    methods: {
        onElementObserved(entries) {
            entries.forEach(({isIntersecting}) => {
                if (!isIntersecting) {
                    this.scrolled = true;
                } else {
                    this.scrolled = false;
                }
            });
        }
    },
    data() {
        return {
            headerObserver: null,
            scrolled: false,
        }
    },
    beforeDestroy() {
        this.headerObserver.disconnect();
    },
    created() {
        this.headerObserver = new IntersectionObserver(
            this.onElementObserved, {}
        );
    }
}
</script>

<style scoped>
#page-header {
    background-image: linear-gradient(#000040, #000030);
    color: white;
    min-height: 100vh;
    /*min-height: -webkit-fill-available;*/
    padding: 15vh 0vh 5vh;
    overflow-x: hidden;
}

.header-main {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header-logo{
    width: 150px;
    height: 150px;
}

.header-content {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header-name {
    font-size: 64px;
    line-height: 1;
    animation: slide-in 1s ease-out forwards;
}

.header-description {
    margin-top: 50px;
    animation: slide-in 1s ease-out forwards;
}

@keyframes slide-in {
    from {
        opacity: 0;
        transform: translateX(100%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}
</style>
