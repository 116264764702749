<template>
    <div id="nav-container" :class="{'scrolled-to-projects': this.scrolledToProjects}">
        <LogoContainer class="nav-logo" :class="{'logo-visible': this.scrolled}"/>
        <div class="nav-toggle-btn" ref="navToggleBtn" @click="toggleNav">
            <svg viewBox="0 0 150 100">
                <path class="nav-line top-line" stroke="white" stroke-width="10" fill="none" d="M0 5 H150" />
                <path class="nav-line middle-line" stroke="white" stroke-width="10" fill="none" d="M0 50 H150" />
                <path class="nav-line middle-line-rev" stroke="white" stroke-width="10" fill="none" d="M0 50 H150" />
                <path class="nav-line bottom-line" stroke="white" stroke-width="10" fill="none" d="M0 95 H150" />
            </svg>
        </div>
        <nav class="nav-menu" ref="navMenu">
            <ul class="nav-list">
                <li class="nav-item">
                    <a href="#page-header" class="nav-link" @click="toggleNav">
                        <div class="nav-icon">
                            🏠
                        </div>
                        <div class="nav-name">Home</div>
                    </a>
                </li>
                <li class="nav-item">
                    <a href="#about-container" class="nav-link" @click="toggleNav">
                        <div class="nav-icon">
                            <!--<svg viewBox="0 0 100 100">
                                <path stroke="white" stroke-width="2" fill="none" d="M10 5 L90 5 L90 95 L10 95 Z" />
                                <path stroke="white" stroke-width="2" fill="none" d="M20 15 H45" />
                                <path stroke="white" stroke-width="2" fill="none" d="M20 25 H45" />
                                <path stroke="white" stroke-width="2" fill="none" d="M20 35 H45" />
                                <circle stroke="white" stroke-width="2" fill="white" cx="70" cy="25" r="10"/>
                                <path stroke="white" stroke-width="2" fill="none" d="M20 55 H80" />
                                <path stroke="white" stroke-width="2" fill="none" d="M20 65 H80" />
                                <path stroke="white" stroke-width="2" fill="none" d="M20 75 H80" />
                                <path stroke="white" stroke-width="2" fill="none" d="M20 85 H80" />
                            </svg>-->
                            👋
                        </div>
                        <div class="nav-name">About me</div>
                    </a>
                </li>
                <li class="nav-item">
                    <a href="#projects-container" class="nav-link" @click="toggleNav">
                        <div class="nav-icon">
                            🔨
                        </div>
                        <div class="nav-name">Projects</div>
                    </a>
                </li>
                <li class="nav-item">
                    <a href="#contact-container" class="nav-link" @click="toggleNav">
                        <div class="nav-icon">
                            ✉
                        </div>
                        <div class="nav-name">Contact me</div>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
import LogoContainer from './LogoContainer.vue'

export default {
    name: 'NavContainer',
    components: {
        LogoContainer
    },
    props: ['scrolled', 'scrolledToProjects'],
    methods: {
        toggleNav() {
            this.$refs.navMenu.classList.toggle("nav-toggled");
            this.$refs.navToggleBtn.classList.toggle("nav-toggle-btn-toggled");
        }
    }
}
</script>

<style scoped>
#nav-container {
    position: fixed;
    top: 0;
    min-height: 50px;
    height: 10vh;
    width: 100%;
    z-index: 1000;
    background-color: #000030;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav-logo {
    width: 15vh;
    height: 15vh;
    margin: auto 20px;
    z-index: 2000;
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

.logo-visible {
    opacity: 1;
}

nav {
    position: absolute;
    top: 10vh;
    right: 0;
    width: 70%;
    z-index: 1000;
    background-color: #000030;
    transform: translateX(100%);
    transition: transform 350ms ease-in-out;
    transform-origin: right;
}

.nav-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    /*margin-left: 30%;*/
    height: 100%;
}

.nav-item {
    text-align: center;
    border-bottom: 1px solid rgba(255,255,255,.25);
    animation: lights-on 1000ms ease-out forwards;
}

.nav-item:first-child {
    border-top: 1px solid rgba(255,255,255,.25);
}

.nav-link {
    text-decoration: none;
    color: white;
    display: flex;
    height: 50px;
    align-items: center;
}

.nav-link:hover {
    background-color: #FFFA79;
    color: #000030;
}

.nav-icon {
    margin: auto 20px;
    /*width: 30px;
    height: 30px;*/
    font-size: 20px;
    transition: transform 200ms ease-in-out;
}

.nav-link:hover .nav-icon {
    transform: scale(1.2);
    transition: transform 200ms ease-in-out;
}

.nav-toggle-btn {
    margin: auto;
    margin-right: 20px;
    cursor: pointer;
}

.nav-toggle-btn svg {
    width: 40px;
    height: 40px;
}

.nav-toggled {
    transform: translateX(0);
}

.nav-toggle-btn .nav-line {
    transform-origin: center;
    transition: transform 250ms ease-in-out;
}

.nav-toggle-btn .top-line {
    transform-origin: left;
    transition: transform 100ms 250ms ease-in-out;
}

.nav-toggle-btn .bottom-line {
    transform-origin: right;
    transition: transform 100ms 250ms ease-in-out;
}

.nav-toggle-btn-toggled .top-line,
.nav-toggle-btn-toggled .bottom-line {
    transition: transform 100ms ease-in-out;
    transform: scaleX(0);
}

.nav-toggle-btn-toggled .middle-line {
    transition: transform 250ms 100ms ease-in-out;
    transform: rotate(135deg);
}

.nav-toggle-btn-toggled .middle-line-rev {
    transition: transform 250ms 100ms ease-in-out;
    transform: rotate(-135deg);
}

.scrolled-to-projects {
    background-color: white;
    color: #000030;
}

@keyframes lights-on {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
</style>
