<template>
    <div id="logo-container">
        <div class="logo">
            <a href="#page-header" class="logo-link">
                <svg viewBox="0 0 100 100">
                    <circle class="circle-large lighting" fill="none" cx="35" cy="35" r="35"/>
                    <circle class="circle-medium lighting" fill="none" cx="70" cy="50" r="30"/>
                    <circle class="circle-small lighting" fill="none" cx="45" cy="75" r="25"/>
                    <path fill="#C0C0C0" stroke="black" stroke-width="2" d="M50 15 l-5 3 l0 12 a10 5 0 0 0 10 0 l0 -12 Z"/>
                    <path fill="none" stroke="black" stroke-width="1" d="M45 19 a10 5 0 0 0 10 0"/>
                    <path fill="none" stroke="black" stroke-width="1" d="M45 22 a10 5 0 0 0 10 -1"/>
                    <path fill="none" stroke="black" stroke-width="1" d="M45 25 a10 5 0 0 0 10 -1"/>
                    <path fill="none" stroke="black" stroke-width="1" d="M45 28 a10 5 0 0 0 10 -1"/>
                    
                    <path class="lighting" fill="none" stroke="black" stroke-width="2" d="M45 30 a10 10 0 1 0 10 0 a10 5 0 0 1 -10 0 Z"/>
                    <text class="logo-initials" fill="transparent" x="65" y="45">HF</text>
                </svg>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LogoContainer',
}
</script>

<style scoped>
.circle-large {
    opacity: 0.5;
}

.circle-medium {
    opacity: 0.3;
}

.circle-small {
    opacity: 0.4;
}

.logo-initials {
    font-size: 24px;
}

.lighting {
    animation: lights-on 2s 1s ease-out forwards;
}

@keyframes lights-on {
    from {
        fill: transparent;
    }
    to {
        fill: #FFFA79;
    }
}
</style>
