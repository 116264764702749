<template>
    <div id="about-container">
        <div class="about-wrapper">
            <h2 class="section-header">About me</h2>
            <p class="about-text">I'm an electrical engineer with a great passion for robotics. Everything from the hardware and sensors to the software and interactions with humans interests me. Of course this also includes AI and machine learning, which is a key part of robotics.</p>
            <p class="about-text">Today I work as a software developer, programming two robots to interact with employees at the office, among other tasks. More lately I have also discovered my interest in web development.</p>
            <div class="about-box">
                <div class="about-me">
                    <img src="../assets/portrait-img-square.jpg" alt="Hannes Fornander" class="about-img">
                    <div class="about-me-text">
                        <h3 class="name-header">Hannes Fornander</h3>
                        <p class="about-text">
                            hannes.fornander@hotmail.com
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AboutContainer',
}
</script>

<style scoped>
#about-container {
    min-height: 100vh;
    padding: 10vh 0vh 5vh;
}

.about-wrapper {
    width: 80%;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section-header {
    margin: 20px 0;
}

.about-me {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.about-box {
    padding: 20px;
    margin-top: 50px;
}

.about-me-text {
    margin: 10px;
}

.name-header {
    font-size: 20px;
}

.about-text {
    margin: 10px 0px;
}

.about-img {
    width: 100px;
    height: auto;
    border-radius: 50%;
}
</style>
