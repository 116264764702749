<template>
    <div id="contact-container">
        <div class="contact-wrapper">
            <h2 class="section-header">Contact me</h2>
            <div class="contact-main">
                <transition name="fade">
                    <div class="form-view" v-if="this.sentForm==='notSent'">
                        <form class="email-form" name="contact" @submit.prevent="handleSubmit" method="POST" netlify-honeypot="bot-field" data-netlify="true">
                            <div class="form-input">
                                <label for="name">Your Name</label><br>
                                <input v-model="form.name" type="text" name="name" required />
                            </div>
                            <div class="form-input">
                                <label for="email">Your Email</label><br>
                                <input v-model="form.email" type="email" name="email" required />
                            </div>
                            <div class="form-input">
                                <label for="message">Message</label><br>
                                <textarea v-model="form.message" name="message" required></textarea>
                            </div>
                            <div class="hidden-field">
                                <label for="bot-field">Don't fill out this field if you are human</label><br>
                                <input name="bot-field" />
                            </div>
                            <button class="form-btn" type="submit">Send</button>
                        </form>
                    </div>
                </transition>
                <transition name="fade">
                    <div class="status-view" v-if="this.sentForm==='success'">
                        <div class="status-icon">
                            <svg viewBox="0 0 100 100">
                                <circle cx="50" cy="50" fill="rgb(66, 206, 157)" r="40" stroke="rgb(66, 206, 157)" stroke-width="10" />
                                <path fill="none" stroke="white" stroke-width="5" d="M30 40 l15 30 l30 -40" />
                            </svg>
                        </div>
                        <h3>Thank you!</h3>
                        <button class="form-btn" type="button" @click="backToForm">Back</button>
                    </div>
                </transition>
                <transition name="fade">
                    <div class="status-view" v-if="this.sentForm==='failed'">
                        <div class="status-icon">
                            <svg viewBox="0 0 100 100">
                                <circle cx="50" cy="50" fill="rgb(243, 110, 110)" r="40" stroke="rgb(243, 110, 110)" stroke-width="10" />
                                <path fill="none" stroke="white" stroke-width="5" d="M30 30 l40 40" />
                                <path fill="none" stroke="white" stroke-width="5" d="M70 30 l-40 40" />
                            </svg>
                        </div>
                        <h3>Oh no! It seems like something went wrong!</h3>
                        <p>You can try again or contact me at hannes.fornander@hotmail.com</p>
                        <button class="form-btn" type="button" @click="backToForm">Back</button>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'ContactContainer',
    data() {
        return {
            form: {
                name: "",
                email: "",
                message: ""
            },
            sentForm: "notSent"
        }
    },
    methods: {
        encode(data) {
            return Object.keys(data)
            .map(
                key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
            )
            .join("&");
        },
        handleSubmit() {
            const vm = this;
            const axiosConfig = {
                header: { "Content-Type": "application/x-www-form-urlencoded" }
            };
            axios.post(
                "/",
                this.encode({
                    "form-name": "contact",
                    ...this.form
                }),
                axiosConfig
            )
            .then(function (response) {
                console.log(response);
                vm.sentForm = "success";
            })
            .catch(function (error) {
                console.log(error);
                vm.sentForm = "failed";
            });
        },
        backToForm() {
            this.sentForm = 'notSent';
        }
    },
}
</script>

<style scoped>
#contact-container {
    min-height: 100vh;
    padding: 10vh 0vh 5vh;
    background-color: white;
    overflow-x: hidden;
    overflow-y: visible;
}

.contact-wrapper {
    width: 80%;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section-header {
    margin: 20px 0;
}

.contact-main {
    position: relative;
    width: 100%;
}

.form-view, .status-view {
    position: absolute;
    width: 100%;
    background-color: #000030;
    color: white;
    padding: 40px 20px;
    border-radius: 5px;
}

.email-form {
    width: 80%;
    margin: auto;
}

.form-input {
    width: 100%;
}

.form-input input {
    width: 100%;
    background-color: white;
    border: 2px solid white;
    border-radius: 50px;
    padding: 10px;
    margin: 10px auto 20px;
}

.form-input textarea {
    width: 100%;
    background-color: white;
    border: 2px solid white;
    padding: 10px;
    margin: 10px auto 20px;
    resize: none;
    height: 100px;
    font-family: Avenir, Helvetica, Arial, sans-serif;
}

.form-input input:focus,
.form-input textarea:focus {
    border-color: #6E74F3;
    outline: none;
}

.form-recaptcha {
    margin: 10px auto;
}

.hidden-field {
    display: none;
}

.form-btn {
    padding: 10px 30px;
    border: 2px solid #FFFA79;
    border-radius: 50px;
    background-color: #000030;
    color: #FFFA79;
    margin: 0px auto;
    cursor: pointer;
    transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
}

.form-btn:hover {
    background-color: #FFFA79;
    color: #000030;
}

.status-view {
    text-align: center;
}

.status-view h3, 
.status-view p {
    margin-bottom: 20px;
}

.status-view svg {
    height: 20%;
    width: 20%;
}


.fade-enter-active {
    transition: transform .5s .5s ease-in-out;
}

.fade-leave-active {
    transition: transform .5s ease-in-out;
}

.fade-enter {
    transform: translateX(-200%);
}

.fade-leave-to {
    transform: translateX(200%);
}
</style>
