<template>
    <div id="projects-container">
        <div class="projects-wrapper">
            <h2 class="section-header">Projects</h2>
            <div class="project-card">
                <div class="project-img" style="background-color: #6EF3B3"></div>
                <div class="project-card-main">
                    <h3 class="card-header">mldragndrop</h3>
                    <p class="card-description">A drag and drop interface made with the deep learning library Keras in mind. Drag and drop Keras layers to create your own architecture and run it on the data that you choose. This project is under development.</p>
                    <ul class="project-tags">
                        <li>Vue</li>
                        <li>Python</li>
                        <li>Deep Learning</li>
                        <li>Keras</li>
                    </ul>
                    <a class="to-project-button" href="https://github.com/hannesfornander/mldragndrop">
                        <svg viewBox="0 0 100 100">
                            <path stroke="#000030" stroke-width="5" fill="none" d="M20 50 H80" />
                            <path stroke="#000030" stroke-width="5" fill="none" d="M55 30 l25 20 l-25 20" />
                        </svg>
                    </a>
                </div>
            </div>
            <div class="project-card">
                <div class="project-img" style="background-color: #6E74F3"></div>
                <div class="project-card-main">
                    <h3 class="card-header">Denoising Monte Carlo Dose Calculations Using a Deep Neural Network</h3>
                    <p class="card-description">My Master thesis explored the possibility of denoising Monte Carlo generated dose calculations with a deep neural network to decrease the computation time. Check it out!</p>
                    <ul class="project-tags">
                        <li>Python</li>
                        <li>Deep Learning</li>
                        <li>Keras</li>
                    </ul>
                    <a class="to-project-button" href="https://kth.diva-portal.org/smash/get/diva2:1366439/FULLTEXT01.pdf">
                        <svg viewBox="0 0 100 100">
                            <path stroke="#000030" stroke-width="5" fill="none" d="M20 50 H80" />
                            <path stroke="#000030" stroke-width="5" fill="none" d="M55 30 l25 20 l-25 20" />
                        </svg>
                    </a>
                </div>    
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProjectsContainer',
    props: ['projectsObserver'],
    mounted() {
        this.projectsObserver.observe(this.$el);
    },
}
</script>

<style scoped>
#projects-container {
    min-height: 100vh;
    padding: 10vh 0vh 5vh;
    background-color: #000030;
    color: white;
}

.projects-wrapper {
    width: 80%;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section-header {
    margin: 20px 0;
}

.project-card {
    background-color: white;
    color: black;
    margin: 10px auto 20px;
    border-radius: 5px;
    overflow: hidden;
    transition: transform 250ms ease-in-out;
}

.project-card:hover {
    transform: scale(1.1);
}

.project-img {
    width: 100%;
    height: 150px;
}

.project-card-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 30px;
}

.card-header {
    margin: 10px 0px;
}

.project-tags {
    display: flex;
    flex-wrap: wrap;
}

.project-tags li {
    background-color: #c4c4ff;
    color: #000030;
    margin: 5px 5px 0px 0px;
    padding: 5px 10px;
    border-radius: 50px;
}

.to-project-button {
    margin: 20px;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 50%; 
    transition: background-color 250ms ease-in-out,
                transform 250ms ease-in-out;
}

.to-project-button:hover {
    cursor: pointer;
    background-color: #dddddd;
    transform: scale(1.1);
}
</style>
