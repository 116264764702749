<template>
    <div id="app">
        <PageHeader :scrolledToProjects="this.scrolledToProjects"/>
        <AboutContainer/>
        <ProjectsContainer :projectsObserver="this.projectsObserver"/>
        <ContactContainer/>
    </div>
</template>

<script>
import PageHeader from './components/PageHeader.vue'
import AboutContainer from './components/AboutContainer.vue'
import ProjectsContainer from './components/ProjectsContainer.vue'
import ContactContainer from './components/ContactContainer.vue'

export default {
    name: 'App',
    components: {
        PageHeader,
        AboutContainer,
        ProjectsContainer,
        ContactContainer
    },
    methods: {
        onElementObserved(entries) {
            entries.forEach(({isIntersecting}) => {
                if (isIntersecting) {
                    //TODO: Fix the projects observer
                    //this.scrolledToProjects = true;
                    //console.log(this.scrolledToProjects);
                } else {
                    //this.scrolledToProjects = false;
                    //console.log(this.scrolledToProjects);
                }
            });
        }
    },
    data() {
        return {
            projectsObserver: null,
            scrolledToProjects: false,
        }
    },
    beforeDestroy() {
        this.projectsObserver.disconnect();
    },
    created() {
        this.projectsObserver = new IntersectionObserver(
            this.onElementObserved, {}
        );
    }
}
</script>

<style>
*, 
*::before, 
*::after {
    box-sizing: border-box;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
}

body {
    position: relative;
    margin: 0;
    min-height: 100vh;
}

h1, 
h2, 
h3, 
p {
    margin: 0;
}

html {
    scroll-behavior: smooth;
}

ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
}
</style>
